/* You can add global styles to this file, and also import other style files */
@use "@cpangular/ng-material-theme/theming";

$idealThemeCfg: (
  primary: #0c6ab6,
  tertiary: #e65151,
);

$daltcoThemeCfg: (
  primary: #0064a8,
);

@include theming.default-theme("ideal-supply", $idealThemeCfg);
@include theming.theme("daltco", $daltcoThemeCfg);

*,
*::before,
*::after {
  box-sizing: border-box;
  position: relative;
}

html,
body {
  --color--brand: var(--palette-ref--tertiary-50);
  --color--brand--contrast: white;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

body {
  /* fix bg wrong color */
  --color--background: var(--palette-ref--neutral-95) !important;
  --color--background--contrast: var(--palette-ref--neutral-10) !important;
  // --mdc-elevated-card-container-color: var(--color--surface);
}

.mat-theme-loaded-marker {
  display: none;
}

a {
  color: var(--color--primary);
  text-decoration: underline;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb--color);
  border-radius: var(--scrollbar-thumb--shape, 0);
  &:hover {
    background-color: var(--scrollbar-thumb--hover-color);
  }
}

::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track--color, transparent);
  &:hover {
    background-color: var(--scrollbar-track--hover-color, var(--scrollbar-track--color, transparent));
  }
}

[mat-list-item] {
  --mat-list-active-indicator-shape: 0;
}

.scroll-container {
  --size--scrollbar: 6px;
  --scrollbar-thumb--shape: 4px;
  --scrollbar-thumb--color: transparent;
  --scrollbar-track--color: transparent;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable;
  height: 100%;
  &:hover {
    --scrollbar-thumb--color: var(--color--background-variant);
    --scrollbar-track--color: var(--color--surface-variant);
    --scrollbar-thumb--hover-color: var(--color--outline);
    --scrollbar-track--hover-color: var(--color--surface-variant);
  }
}
