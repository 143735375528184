a {
  color: var(--color--primary);
  text-decoration: none;
  &:visited {
    color: var(--color--secondary);
  }
}

mat-paginator {
  .mat-mdc-form-field-flex {
    position: static;
  }

  .mat-mdc-paginator-range-label {
    &::after {
      content: " items";
    }
  }
}

[theme-density] {
  --mat-form-field-filled-label-display: block;
}

.legacy-page {
  display: flex !important;
  flex-direction: column;
  align-items: center;

  .mat-mdc-form-field-flex,
  .mat-mdc-chip-action {
    position: static !important;
  }

  .mat-mdc-chip-remove::after {
    top: 1px !important;
    width: 22px;
    height: 22px;
    mat-icon {
      overflow: visible;
    }
  }

  mat-paginator {
    margin-block: 0.6em;
    margin-inline: 2px;
  }

  mat-card:has(mat-paginator) {
    --mat-paginator-container-text-color: var(--color--surface--contrast);
    --mat-paginator-container-background-color: var(--color--surface);
    background-color: var(--mat-paginator-container-background-color);
  }

  .mat-mdc-table {
    background-color: transparent !important;
  }

  /* Fixes for material components */
  mat-form-field.ng-untouched {
    > .mat-mdc-form-field-subscript-wrapper {
      mat-error {
        display: none;
      }
    }
  }

  mat-form-field.ng-invalid:not(.ng-untouched) {
    --mdc-outlined-text-field-error-focus-label-text-color: var(--color--error) !important;
    --mdc-outlined-text-field-error-label-text-color: var(--color--error) !important;
    --mdc-outlined-text-field-error-hover-label-text-color: var(--color--error) !important;
    --mdc-outlined-text-field-error-focus-outline-color: var(--color--error) !important;
    --mdc-outlined-text-field-error-hover-outline-color: var(--color--error) !important;
    --mdc-outlined-text-field-error-outline-color: var(--color--error) !important;
    --mdc-outlined-text-field-hover-outline-color: var(--color--error) !important;
    --mdc-outlined-text-field-input-text-color: var(--color--error) !important;
    --mdc-outlined-text-field-hover-label-text-color: var(--color--error) !important;
  }

  mat-form-field.ng-untouched,
  mat-form-field.ng-valid {
    --mdc-outlined-text-field-error-focus-label-text-color: var(--color--background--contrast) !important;
    --mdc-outlined-text-field-error-label-text-color: var(--color--background--contrast) !important;
    --mdc-outlined-text-field-error-hover-label-text-color: var(--color--background--contrast) !important;
    --mdc-outlined-text-field-error-focus-outline-color: var(--color--background--contrast) !important;
    --mdc-outlined-text-field-error-hover-outline-color: var(--color--background--contrast) !important;
    --mdc-outlined-text-field-error-outline-color: var(--color--background--contrast) !important;
    --mdc-outlined-text-field-hover-outline-color: var(--color--background--contrast) !important;
    --mdc-outlined-text-field-input-text-color: var(--color--background--contrast) !important;
    --mdc-outlined-text-field-hover-label-text-color: var(--color--background--contrast) !important;
  }

  .mat-mdc-select-disabled {
    .mat-mdc-select-placeholder {
      color: var(--mat-select-disabled-trigger-text-color);
    }
  }
}
mat-calendar-header {
  .mat-calendar-previous-button,
  .mat-calendar-next-button {
    min-width: 38px;
    min-height: 38px;
  }
}

body {
  --mdc-dialog-container-shape: 8px;
  line-height: 1.2;
}
