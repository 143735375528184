@use "sass:color";
@use "sass:math";

$lph-cols: 12;

@keyframes load-ph-animation {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
}

// @mixin make-load-ph-field($name) {
//   #{$name} {
//     @content;
//     position: relative;
//     opacity: 1;
//     transition: width min-width max-width opacity 1.5s linear;
//     &::before {
//       content: " ";
//       display: block;
//       position: absolute;
//       top: 1px;
//       left: 1px;
//       width: calc(100% - 0.5em);
//       height: 1em;
//       opacity: 0;
//       pointer-events: none;
//       border-radius: var(--loading-placeholder--field-shape, 2px);
//       background-color: var(--loading-placeholder--background-color, var(--color--surface-variant, #ced4da));
//       transition: all 1.5s linear;
//       z-index: 100;
//     }
//   }
//   .load-ph-on #{$name} {
//     min-width: var(--load-ph--col-width, auto);
//     max-width: var(--load-ph--col-width, auto);
//     min-height: 1.2em;
//     line-height: 0;
//     opacity: 0;
//     &::before {
//       opacity: 1;
//       animation: load-ph-animation 2.5s linear infinite;
//     }
//   }
// }

// @mixin make-load-ph-col($name, $col, $maxCols) {
//   @include make-load-ph-field(#{$name}-#{$col}) {
//     --load-ph--col-width: calc(100% / #{$maxCols} * #{$col});
//   }
// }

// @include make-load-ph-field(".load-ph") {
//   --load-ph--col-width: auto;
// }

// @include make-load-ph-col(".load-ph", 1, 12);
// @include make-load-ph-col(".load-ph", 2, 12);
// @include make-load-ph-col(".load-ph", 3, 12);
// @include make-load-ph-col(".load-ph", 4, 12);
// @include make-load-ph-col(".load-ph", 5, 12);
// @include make-load-ph-col(".load-ph", 6, 12);
// @include make-load-ph-col(".load-ph", 7, 12);
// @include make-load-ph-col(".load-ph", 8, 12);
// @include make-load-ph-col(".load-ph", 9, 12);
// @include make-load-ph-col(".load-ph", 10, 12);
// @include make-load-ph-col(".load-ph", 11, 12);
// @include make-load-ph-col(".load-ph", 12, 12);
@function col-size($numCols, $totalCols) {
  @return math.div($numCols, $totalCols) * 100%;
}

@mixin make-col($numCols, $totalCols) {
  .loading-ph_is-loading.lph-cols-#{$numCols} {
    min-width: col-size($numCols, $totalCols);
  }
}

.loading-ph_placeholder-anchor {
  z-index: 100;
  .loading-ph_placeholder {
    border-radius: var(--loading-placeholder--field-shape, 2px);
    background-color: var(--loading-placeholder--background-color, var(--color--surface-variant, #ced4da));
    animation: load-ph-animation 2.5s linear infinite;
    transition: width height 1.5s linear;
  }
}

.loading-ph_is-loading {
  min-height: 1em;
  opacity: 0;
  pointer-events: none;
}

@for $i from 1 through $lph-cols {
  @include make-col($i, $lph-cols);
}

// @include make-col(1, $lph-cols);

// $ph-direction: ltr !default;
// $ph-bg: #fff !default;
// $ph-color: #ced4da !default;
// $ph-border: 1px solid color.scale($ph-bg, $lightness: -10%) !default;
// $ph-border-radius: 2px !default;

// $ph-cols: 12 !default;
// $ph-cols-remove-odd: true !default;
// $ph-gutter: 30px !default;
// $ph-spacer: 15px !default;

// $ph-avatar-border-radius: 50% !default;

// $ph-animation-duration: 0.8s !default;

// .ph-item {
//   &,
//   *,
//   ::after,
//   ::before {
//     box-sizing: border-box;
//   }

//   position: relative;
//   display: flex;
//   flex-wrap: wrap;
//   padding: $ph-gutter ($ph-gutter * 0.5) ($ph-gutter - $ph-spacer) ($ph-gutter * 0.5);
//   margin-bottom: $ph-gutter;
//   overflow: hidden;
//   direction: $ph-direction;
//   background-color: $ph-bg;
//   border: $ph-border;
//   border-radius: $ph-border-radius;

//   &::before {
//     position: absolute;
//     top: 0;
//     right: 0;
//     bottom: 0;
//     left: 50%;
//     z-index: 1;
//     width: 500%;
//     margin-left: -250%;
//     pointer-events: none;
//     content: " ";
//     background: linear-gradient(to right, rgba($ph-bg, 0) 46%, rgba($ph-bg, 0.35) 50%, rgba($ph-bg, 0) 54%) 50% 50%;
//     animation: ph-animation $ph-animation-duration linear infinite;
//   }

//   > * {
//     display: flex;
//     flex: 1 1 auto;
//     flex-flow: column;
//     padding-right: ($ph-gutter * 0.5);
//     padding-left: ($ph-gutter * 0.5);
//     margin-bottom: $ph-spacer;
//   }
// }

// .ph-row {
//   display: flex;
//   flex-wrap: wrap;
//   margin-top: -($ph-spacer * 0.5);

//   div {
//     height: 10px;
//     margin-top: ($ph-spacer * 0.5);
//     background-color: $ph-color;
//   }

//   .big,
//   &.big div {
//     height: 20px;
//   }

//   .empty {
//     background-color: rgba($ph-bg, 0);
//   }
// }

// @mixin ph-make-col($size, $columns) {
//   .ph-col-#{$size} {
//     flex: 0 0 math.percentage(math.div($size, $columns));
//   }
// }

// @for $i from 1 through $ph-cols {
//   @if $ph-cols-remove-odd and $i % 2 == 0 {
//     @include ph-make-col($i, $ph-cols);
//   } @else if not $ph-cols-remove-odd {
//     @include ph-make-col($i, $ph-cols);
//   }
// }

// // TODO make this a `.ph-wrap` class for no padding, no background, no animation,
// // and remove direction from `.ph-item`. This is a breaking change
// [class*="ph-col"] {
//   direction: $ph-direction;

//   > * {
//     + .ph-row {
//       margin-top: 0;
//     }

//     + * {
//       margin-top: ($ph-spacer * 0.5);
//     }
//   }
// }

// .ph-avatar {
//   position: relative;
//   width: 100%;
//   min-width: 60px;
//   overflow: hidden;
//   background-color: $ph-color;
//   border-radius: $ph-avatar-border-radius;

//   &::before {
//     display: block;
//     padding-top: 100%;
//     content: " ";
//   }
// }

// .ph-picture {
//   width: 100%;
//   height: 120px;
//   background-color: $ph-color;
// }

// @keyframes ph-animation {
//   0% {
//     transform: translate3d(-30%, 0, 0);
//   }

//   100% {
//     transform: translate3d(30%, 0, 0);
//   }
// }
