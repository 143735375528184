@use "sass:meta";

[theme-mode="light"] {
  @include meta.load-css("highlight.js/styles/a11y-light");
}

[theme-mode="dark"] {
  @include meta.load-css("highlight.js/styles/a11y-dark");
}

pre[markdown-content] {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
  unicode-bidi: inherit;
  margin: 0;
}
